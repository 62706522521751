<template>
	<el-dialog title="编辑用户" :visible.sync="visiable">
		<el-form  ref="form" :model="formData" label-width="120px">
			<el-row>
				<el-col :span="12">
					<el-form-item label="用户账号：">
						<el-input v-model="formData.accountNo" disabled></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="手机：">
						<el-input v-model="formData.mobile" disabled></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="姓名：">
						<el-input v-model="formData.realName" disabled></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="身份证：">
						<el-input v-model="formData.idCard" disabled></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="所在城市：">
						<el-input v-model="formData.city" disabled></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="详细地址：">
						<el-input v-model="formData.address" disabled></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<el-image style="width: 100px; height: 100px" :src="headUrl" fit="fill"></el-image>
		<div slot="footer" class="dialog-footer">
			<el-button @click="close()">关闭</el-button>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				formData: {},
				headUrl: ""
			};
		},
		props: {},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.formData = {};
				this.headUrl = "";
				this.get();
			},
			//获取数据
			get() {
				if (this.dialogObj.id) {
					this.$get("/user-api/qs/user/" + this.dialogObj.id).then((res) => {
						if (res.code == 1000) {
							this.formData = res.data
							if (this.formData.head) {
								this.headUrl = configApi.photoURL + this.formData.head
							}
						} else {
							this.$message.error(res.msg);
						}
					});
				}
			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss'>
</style>
